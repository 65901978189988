
import fireTaboolaEvents from '../helpers/fireTaboolaEvents'
import fireRTBHouseEvents from '@/helpers/fireRTBHouseEvents'
import analytics from '@/helpers/analytics'
import criteo from '@/helpers/criteo'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    itemPrice () {
      if (this.item.price !== undefined) {
        return this.item.price.price ?? this.item.price
      }
      return 'XXX'
    }
  },
  mounted () {
    if (!this.$config.IS_PRODUCTION) return

    const headers = {
      'User-Agent': window.navigator.userAgent,
      'x-original-client-ip': this.$store.state.ip
    }

/*
    if (this.item.impressionUrl) {
      this.$axios.$get(this.item.impressionUrl, {
        headers
      })
    }

    if (this.item.trackUrl) {
      this.$axios.$get(this.item.trackUrl, {
        headers
      })
    }
*/
  },
  methods: {
    getGoogleConversion() {
      if ((this.$store.state.siteConfig.googleConversions !== undefined) &&
        (this.$store.state.siteConfig.googleConversions['inlineAds'] !== undefined)) {
        return this.$store.state.siteConfig.googleConversions['inlineAds']
      }

      return null;
    },
    open () {
      analytics.track('inline-ad-click', this)
      console.info('Open ad deeplink URL')
      window.newTab = window.open(this.item.deepLink, '_blank')

      if (this.item.cpc > 0) {
        // Google conversion
        const googleConversion = this.getGoogleConversion()

        if (this.$gtag && googleConversion) {
          this.$gtag.event('conversion', {
            "send_to": googleConversion,
            "value": Number(this.item.bid ?? 0).toFixed(2),
            "currency": 'USD'
          });
        }

        // Bing conversion
        if (window.uetq) {
          window.uetq.push("event", "InlineAdclickout", {
            "revenue_value": Number(this.item.bid ?? 0).toFixed(2),
            "currency": "USD"
          })
        }
      }

      // Criteo product tag
      this.pushCriteoParams()

      // RTBHouse
      this.fireRTBHouseEvent()

      try {
        // Taboola event
        fireTaboolaEvents('inline-clickout', this)
      } catch (error) {
        console.info('Error firing Taboola event', error)
      }
    },
    fireRTBHouseEvent () {
      try {
        const flightSearchData = this.$store.state.flightsSearchFormData

        // RTBHouse
        fireRTBHouseEvents('flight', 'conversion', {
          id: this.item.id + '-' + Date.now(),
          origin: flightSearchData.origin,
          destination: flightSearchData.destination,
          cpc: this.item.bid,
          outboundDate: flightSearchData.outboundDate,
          inboundDate: flightSearchData.inboundDate,
          adults: flightSearchData.adults,
          children: flightSearchData.children,
          roundtrip: flightSearchData.roundtrip
        })
      } catch (error) {
        console.info('Error firing RTB event')
      }
    },
    pushCriteoParams () {
      const customerId = this.$store.state.entryId
      const id = customerId + this.item.id
      const currency = this.item.cpcEstimate ? this.item.cpcEstimate.currency : 'USD'
      const price = this.item.cpcEstimate ? this.item.cpcEstimate.price : 0

      const options = { customerId, currency, price, id }
      criteo.purchaseTag(this.$store, options)
    }
  }
}
