export default function (type = 'flight', section = '', formData = {}) {
  // if (!window || !window.rtbhEvents) return
  if (!window || !window.rtbhEvents) return

  const rtbhEvents = window.rtbhEvents
  let payload

  if (section === 'home') {
    payload = [
      {
        eventType: section
      },
      {
        eventType: 'custom',
        name: 'type',
        value: type
      },
      {
        eventType: 'uid',
        id: 'unknown'
      }
    ]
  }
  if (section === 'offer') {
    payload = [
      {
        eventType: section,
        offerId: `flight-${formData.destination}`
      },
      {
        eventType: 'custom',
        name: 'type',
        value: type
      },
      {
        eventType: 'custom',
        name: 'flight-departure',
        value: `from-${formData.origin}`
      },
      {
        eventType: 'custom',
        name: 'flight-destination',
        value: `to-${formData.destination}`
      },
      {
        eventType: 'custom',
        name: 'flight-startdate',
        value: formData.outboundDate
      },
      {
        eventType: 'custom',
        name: 'flight-enddate',
        value: formData.inboundDate
      },
      {
        eventType: 'custom',
        name: 'flight-adults',
        value: formData.adults
      },
      {
        eventType: 'custom',
        name: 'flight-children',
        value: formData.children
      },
      {
        eventType: 'custom',
        name: 'flight-roundtrip',
        value: formData.roundtrip
      },
      {
        eventType: 'uid',
        id: 'unknown'
      }
    ]
  }
  if (section === 'startorder') {
    payload = [
      {
        eventType: section
      },
      {
        eventType: 'custom',
        name: 'type',
        value: type
      },
      {
        eventType: 'uid',
        id: 'unknown'
      }
    ]
  }
  if (section === 'conversion') {
    payload = [
      {
        eventType: section,
        conversionClass: 'action',
        conversionSubClass: 'clickthrough',
        conversionId: `${type}-${formData.id}`,
        offerIds: [`flight-${formData.destination}`],
        conversionValue: formData.cpc
      },
      {
        eventType: 'custom',
        name: 'type',
        value: type
      },
      {
        eventType: 'custom',
        name: 'flight-departure',
        value: `from-${formData.origin}`
      },
      {
        eventType: 'custom',
        name: 'flight-destination',
        value: `to-${formData.destination}`
      },
      {
        eventType: 'custom',
        name: 'flight-startdate',
        value: formData.outboundDate
      },
      {
        eventType: 'custom',
        name: 'flight-enddate',
        value: formData.inboundDate
      },
      {
        eventType: 'custom',
        name: 'flight-adults',
        value: formData.adults
      },
      {
        eventType: 'custom',
        name: 'flight-children',
        value: formData.children
      },
      {
        eventType: 'custom',
        name: 'flight-roundtrip',
        value: formData.roundtrip
      },
      {
        eventType: 'uid',
        id: 'unknown'
      }
    ]
  }
  if (section === 'custom') {
    payload = [
      {
        eventType: section,
        name: 'type',
        value: formData.vertical
      },
      {
        eventType: 'custom',
        name: 'travel',
        value: `${formData.vertical}-${formData.timestamp}`
      },
      {
        eventType: 'uid',
        id: 'unknown'
      }
    ]
  }
  if (section === 'call') {
    payload = [
      {
        eventType: 'conversion',
        conversionClass: 'action',
        conversionSubClass: section,
        conversionId: `${type}-${formData.id}`,
        offerIds: [`flight-${formData.destination}`],
        conversionValue: null,
        conversionCurrency: null
      },
      {
        eventType: 'uid',
        id: 'unknown'
      }
    ]
  }

  rtbhEvents.push(...payload)
}
